import React,{Component} from 'react'
import whatsappIcon from "../images/whatsApp.svg";
import india from "../images/india-flag.svg";
import googlePlay from "../images/googlePlay.svg"
import appStore from "../images/appStore.svg"
import error from "../images/error.svg";
import line from "../images/line.svg";

class Forms extends Component {
    constructor(){
       super();
        this.state = {
            Name: '',
            MobileNumber: '',
            CoachingName:'',
            Email:'',
            utm_source: '',
            utm_medium: '',
            utm_campaign: '',
            success: false,
            nameVal:false,
            coachingVal:false,
            phoneVal:false,
            emailVal:false,
           
           
        }

    }

    componentDidMount = () => {
        const vars = {};
        const parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });

        this.setState({
            utm_source: vars["utm_source"],
            utm_medium: vars["utm_medium"],
            utm_campaign: vars["utm_campaign"]
        })


    } 


    nameChange = (e) => {
        this.setState({
            nameVal:false,
            Name: e.target.value,
            
        })
    }

    coachingNameChange =(e)=>{
        this.setState({
            coachingVal:false,
            CoachingName:e.target.value
        })
    }

    emailChange =(e)=>{
       this.setState({
            emailVal:false,
            Email:e.target.value
            })
        
    }

    mobileChange = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            if (e.target.value.length <= 10) {
                
                this.setState({
                    phoneVal:false,
                    MobileNumber: e.target.value
                })
            }
          
        }
       
    }

    gtag_report_conversion=(url)=> {
        window.ga('send', 'event', 'Homepage_form', 'submit', 'form_submit');
        // var callback = function () {
        //     if (typeof(url) != 'undefined') {
        //     window.location = url;
        //     }
        // };
        //   window.gtag('event', 'conversion', {
        //     'send_to': 'AW-825420150/iPYBCOu_ktgBEPbSy4kD',
        //     'event_callback': callback
        // });
        // return false;
        }


    formData = (e) => {
         var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
        e.preventDefault();
       if(this.state.MobileNumber.length <10 ){
           
            if (this.state.MobileNumber.length < 10) {
            this.setState({phoneVal:true})
            }

            // if(this.state.Name.length <3) {
            // this.setState({nameVal:true})
            // }

            // if(this.state.Email == '' || !reEmail.test(this.state.Email)) {
            // this.setState({emailVal:true})
           
            // }
            // if(this.state.CoachingName.length <3) {
            // this.setState({coachingVal:true})
            
            // }

       }

        else {
            const body = { ...this.state, appointmentDate: '' };
            let message=`Dear ${this.state.Name},

                        It’s time to take your coaching institute online! Here are just some of the things you can accomplish using the My Institute app.

                        - Create and manage batches
                        - Conduct live classes
                        - Share study materials, assignments, video lectures etc.
                        - Take online tests

                        Download now https://play.google.com/store?hl=en_IN.`;
            let payload={
                    // type:"3",
                    // instituteName:this.state.CoachingName,
                    // name :this.state.Name,
                    // email:this.state.Email,
                    mobile:this.state.MobileNumber,
                    // campaignName:this.state.utm_campaign,
                    // utmMedium:this.state.utm_medium,
                    // utmSource:this.state.utm_source,
                    countryCode:'91'
                }
                fetch(`https://api.classplusapp.com/v2/freemium/new/register`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": 'application/json',
                    },
                    body:JSON.stringify(payload)}
                    ).then(response=>{
                        if (response.status >= 200 && response.status < 300) {
                        return this.successPopUp(),this.clearFormData()
                        ,this.gtag_report_conversion()
                        }
                        else{
                            return alert("Something went wrong")
                        }
                    }
                    )
            }


    }

    successPopUp = () => {
        
        this.setState({
            success:true})
    }

    clearFormData = () => {
       
        this.setState({
            Name: '',
            MobileNumber: '',
            Email:'',
            CoachingName:''
        })
    }

    handleKeyPress = (e) =>{
        if(e.keyCode == 13){
            e.preventDefault();
           this.formData();
        }
    }

    // clearPopup = () => {
    //     //alert("Success");
    //     console.log("Success3")
    //     console.log(this.state.success, "Success3")
    //     this.setState({
    //         success: false
    //     })
    //     console.log(this.state.success,"Success4")
    // }
    render() {

   
       
            return (
                <div style={{"width":"100%",padding:"0 32px"}}>
                    {this.state.success == true ? 
                    <div className="successPopup successOut">
                        <div className="successPopup__content">
                        We have registered your response successfully and will get back to you soon. Thank you!
                        </div>
                        </div>: <div/>}
                    <form className="form" >

                         {/* <input type="text" className="form__input" placeholder="Your Institute's Name *" required onChange={this.coachingNameChange} value={this.state.CoachingName} style={this.state.coachingVal?{border:"1px solid #BF0711"}:{border:"1px solid #C4CDD5"}}/>
                         {this.state.coachingVal && 
                         <div style={{display:"flex",aligItems:"center"}}>
                             <img src={error} alt="error" style={{marginRight:"8px"}}/>
                             <p style={{color:"#BF0711"}}>Enter Institute Name</p>
                         </div>}
                         <input type="text" className="form__input" placeholder="Your Name *" required onChange={this.nameChange} value={this.state.Name} style={this.state.nameVal?{border:"1px solid #BF0711"}:{border:"1px solid #C4CDD5"}}/>
                         {this.state.nameVal && 
                         <div style={{display:"flex",aligItems:"center"}}>
                             <img src={error} alt="error" style={{marginRight:"8px"}}/>
                            <p style={{color:"#BF0711"}}>Enter Your Name</p>
                        </div>}
                          <input type="email" className="form__input" placeholder="Your Email Address *" required onChange={this.emailChange} value={this.state.Email} style={this.state.emailVal?{border:"1px solid #BF0711"}:{border:"1px solid #C4CDD5"}}/>
                          {this.state.emailVal && 
                          <div style={{display:"flex",aligItems:"center"}}>
                                 <img src={error} alt="error" style={{marginRight:"8px"}}/>
                                <p style={{color:"#BF0711"}}>Enter Email Address</p>
                            </div>
                            } */}
                            <div style={{position:"relative"}}>
                                 <input type="text" 
                                        className="form__input yourNumber-input" 
                                        placeholder="Your WhatsApp Number *" 
                                        required 
                                        onChange={this.mobileChange} 
                                        value={this.state.MobileNumber} 
                                        style={this.state.phoneVal?{border:"1px solid #BF0711"}:{border:"1px solid #C4CDD5"}}
                                        onKeyPress={this.handleKeyPress}
                                        />
                                <img src={india} alt="india" className="indiaFlag"/>
                                <img src={line} alt="line" className="phone-line"/>
                            </div>
                            {this.state.phoneVal && 
                            <div style={{display:"flex",aligItems:"center"}}>
                                <img src={error} alt="error" style={{marginRight:"8px"}}/>
                                <p style={{color:"#BF0711"}}>Enter WhatsApp Number</p>
                            </div>}
                        
  
                            <div style={{cursor:"pointer"}}>
                                <button className="whatsappBtn form__button btn whatsappAnchor" onClick={this.formData}>
                                        <img alt="" src={whatsappIcon} style={{ height: "24px", width: "24px" }} />
                                        <p style={{paddingLeft:"8px"}}> Get Free App </p>
                                </button>
                            </div>

                            {/* <div className="form-Bottom">
                                <img src={googlePlay} alt="googlePlay" style={{width:"48%"}}/>
                                <img src={appStore} alt="appStore" style={{width:"48%"}}/>
                            </div> */}
                
                     </form> 
                    </div>
            )
        }
    }

   

export default Forms