import React, { Component } from 'react';
import whatsapp from '../images/whatsappImg.svg';
import cover from '../images/studentCover.png';
import learn from '../images/learn.svg';
import communicate from '../images/commu.svg';
import measure from '../images/measure.svg';
class StudentHeader extends Component {
	handleClick = () => {
		let message = `Dear Teacher,

More than 5,000 teachers are teaching over 1,00,000 students online everyday using the Classplus Lite!

Take your classroom online in less than 60 seconds. Download the app now:  https://bit.ly/3iBCrdq. You can use the app to

👩‍🎓 Create and manage batches of students
📹 Conduct live classes
💬 Chat with parents and students
📘 Share study material and take tests
📣 Send announcements
✅ And much more ...`;
		window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, `_blank`);
	};
	render() {
		return (
			<div className="container">
				<div className="firstBox">
					<p className="firstBox-heading">Your education deserves no obstructions.</p>
					<p className="firstBox-subheading">
						Get the best classroom learning experience with
						<span style={{ color: '#00A7F3' }}> Classplus Lite </span>by taking your tuition digital during
						these difficult times.
					</p>
					<button className="whatsapp-button" onClick={this.handleClick}>
						<img src={whatsapp} alt="whatsapp" style={{ marginRight: '8px' }} />Share With Your Tutor
					</button>
					<p className="firstBox-secondHeading">Kahin aapke sapne toot na jayein</p>
					<p className="firstBox-secondSubheading">कहीं आपके सपने टूट न जाएं</p>
					<img src={cover} alt="cover" className="coverImage" />
					<p className="firstBox-text">
						More than{' '}
						<span style={{ color: '#00A7F3', fontWeight: '600', fontSize: '26px' }}>1,00,000</span> students
						have begun their online learning journey using the{' '}
						<span style={{ color: '#00A7F3', margin: '0px' }}>Classplus Lite</span>
					</p>
				</div>

				<div className="secondBox">
					<p className="secondBox-heading">APP FEATURES</p>
					<p className="secondBox-subheading">Enabling tutors to embrace the future</p>
					<p className="secondBox-text">
						Sign up as a Student and embark on your online learning journey on this all-in-one app.
					</p>
				</div>
				<div className="thirdBox">
					<div className="thirdBox-item">
						<div className="thirdBox-left">
							<img src={learn} alt="learn" />
						</div>
						<div className="thirdBox-right">
							<p className="thirdBox-right-heading">Learn</p>
							<p className="thirdBox-right-subheading">
								Attend<strong style={{ color: '#212326' }}> live classes </strong>by your teachers,
								download <strong style={{ color: '#212326' }}>study material</strong>, submit your{' '}
								<strong style={{ color: '#212326' }}>homework </strong>, and much more ...
							</p>
						</div>
					</div>
					<div className="thirdBox-item">
						<div className="thirdBox-left">
							<img src={communicate} alt="communicate" />
						</div>
						<div className="thirdBox-right">
							<p className="thirdBox-right-heading">Communicate</p>
							<p className="thirdBox-right-subheading">
								Stay connected with your teacher <strong style={{ color: '#212326' }}>24x7</strong>{' '}
								using our <strong style={{ color: '#212326' }}>Chat </strong> platform and get all your
								doubts cleared
							</p>
						</div>
					</div>
					<div className="thirdBox-item" style={{ borderBottom: '1px solid #E5E5E5' }}>
						<div className="thirdBox-left">
							<img src={measure} alt="measure" />
						</div>
						<div className="thirdBox-right">
							<p className="thirdBox-right-heading">Measure</p>
							<p className="thirdBox-right-subheading">Measure your progress and continue to improve!</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default StudentHeader;
