import React,{Component} from 'react';
import Landing from './Landing';
import PointerSection from './PointerSection';
import Testimonial from "./Testimonial";
import FrequentQuestions from "./FrequentQuestions"
import ParentLanding from "./ParentLanding"
import TestimonialImage from "./TestimonialImage"
import whatsapp from "../images/whatsappImg.svg";
class Home extends Component{
    constructor(){
        super()
        this.state={
            
        }
    }

    componentDidMount() {

        let chatBotElement = document.getElementById('chat-bot-launcher-container')

        if (chatBotElement) {
            chatBotElement.style.display = 'block'
        }
        
    }

    render(){
    
    return (
        <div>
    
            <Landing />
            <PointerSection/>
            <Testimonial/>
            <FrequentQuestions/>
        </div>
    )
}
}

export default Home