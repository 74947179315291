import React,{Component,useState} from 'react';
import QuestionBox from "./QuestionBox"
function EventQuestions(){
const [data,setData]=useState([
            { clicked:false,
              ques:`How to create a new event in the timetable?`,
              ans:`To create a new timetable:
                1. Click on the “TIMETABLE” tab.
                2. Tap on “+” to create a new timetable.
                3. Enter the Event Name & details of Batch & Time and click on Done. `
            },
           
            
          ]);
        const handleClicked =index=>{
        setData(data.map((item,i)=>{
            if(i===index){
              item.clicked=!item.clicked
            }
            else{
              item.clicked=false
            }
          return item
        })
        )
    }
    return (
      <div style={{padding:"60px 0 150px 0"}}>
      {data.map((dt,index)=>{
          return(
            <QuestionBox data={dt} index={index} length={data.length} handleClicked={handleClicked}/>
          )
          })
      }
      </div>
    )
}

export default EventQuestions