import React,{Component} from 'react';
import GettingStarted from "./GettingStarted"
import ProductDetails from "./ProductDetails"
import StudentQuestion from "./StudentQuestion"
class SeeMore extends Component{
    constructor(){
        super();
        this.state={
            startedClicked:true,
            detailsClicked:false,
            studentClicked:false
           
        }
    }

    componentDidMount(){
        let params = (new URL(document.location)).searchParams;
        let user =params.get("userType")
        if(user==2 || user==1 )
            {  
                this.setState({studentClicked:true,startedClicked:false})
            }           
    }

    handleStartedClicked=()=>{
        this.setState({startedClicked:true,detailsClicked:false,studentClicked:false})

    }

    handleDetailsClicked=()=>{
        this.setState({detailsClicked:true,startedClicked:false,studentClicked:false})

    }

    handleStudentClicked=()=>{
        this.setState({detailsClicked:false,startedClicked:false,studentClicked:true})
    }

    render(){
           let params = (new URL(document.location)).searchParams;
           let user =params.get("userType")
           let {startedClicked,detailsClicked,studentClicked}=this.state
    return (
        <div className="seemore">
           <div className="seemore-first">
               <p className="seemore-head">
                 FAQs
               </p>
               <p className="seemore-subhead">
                If you’re new to here, then this will help you learn more about the platform and its features.

               </p>

               <p className="seemore-categories">FAQ Categories</p>
               <div className="seemore-button-div">
                   

                  {user ?
                    <>
                        { user==1 || user==2 ?
                             '':
                        <button className={startedClicked ? "button-clicked":"button-notclicked"} onClick={this.handleStartedClicked}>
                            Getting Started
                        </button> }
                        {user==3 ? <button className={detailsClicked ? "button-clicked button-margin":"button-notclicked button-margin"} onClick={this.handleDetailsClicked}>
                            Product Details (Tutor)
                        </button>:''}
                         <button className={studentClicked ? "button-clicked student-margin ":"button-notclicked student-margin "} style={user==2 || user==1 ?{marginLeft:"14px"}:{}} onClick={this.handleStudentClicked}>
                            Product Details (Students & Parents)
                        </button>
                    </>
                    :
                    <>
                        <button className={startedClicked ? "button-clicked":"button-notclicked"} onClick={this.handleStartedClicked}>
                            Getting Started
                        </button>
                        <button className={detailsClicked ? "button-clicked button-margin":"button-notclicked button-margin"} onClick={this.handleDetailsClicked}>
                           Product Details (Tutor)
                        </button>
                            <button className={studentClicked ? "button-clicked student-margin ":"button-notclicked student-margin "} onClick={this.handleStudentClicked}>
                            Product Details (Students & Parents)
                        </button>

                    </>
                }
               </div>
           </div>
           <div className={startedClicked ?"seemore-second": "seemore-second-details"}>
               {startedClicked && <GettingStarted/>}
               {detailsClicked && <ProductDetails/>}
               {studentClicked&& <StudentQuestion/>}

           </div>


        </div>
    )}
}

export default SeeMore