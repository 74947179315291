import React from 'react';
//import {Link} from 'react-router-dom';
import ClassplusLogo from '../images/Classplus-Lite-footer.svg';
import { ScrollTo } from 'react-scroll-to';

export const Navigation = () => {
	const params = new URL(document.location).searchParams;
	const user = params.get('userType');
	return (
		<div>
			<div className="blueStrip" />
			<div className="navigation">
				<div className="navigation__logo">
					{user ? (
						<img src={ClassplusLogo} alt="Classplus" className="navigation__logo--img" />
					) : (
						<a href="/">
							<img src={ClassplusLogo} alt="Classplus" className="navigation__logo--img" />
						</a>
					)}
				</div>
				{/* <div className="navigation__book">
                    <ScrollTo>
                        {({ scrollTo }) => (
                            <button className="btn--bookADemo btn" onClick={() => { window.innerWidth > 600 ? (window.innerWidth > 1400 ? (window.innerWidth > 1600 ? scrollTo({ y: 4600, smooth: true }) : scrollTo({ y: 3900, smooth: true })) : scrollTo({ y: 4100, smooth: true })) : (window.innerWidth < 1300 ? (((window.innerWidth < 600) ? scrollTo({ y: 4500, smooth: true }) : scrollTo({ y: 3600, smooth: true }))) : scrollTo({ y: 3800, smooth: true })) }}>Get your Instant App</button>
                           
                        )}
                    </ScrollTo>

            </div> */}
			</div>
		</div>
	);
};

export default Navigation;
