import React, { Component } from 'react';
import TestCard from './TestCard';
import Carousel from '../UI/Carousel/Carousel';
import user from '../../src/images/user.svg';
class Testimonial extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let { isParent, isStudent } = this.props;
		let data = [];
		if (isParent) {
			data = [
				{
					content: `In this hard time when all of the world is stopped due to covid-19, this app enables my child to continue his studies through the excellent teachers. The interaction of the teachers and the students are just like the classroom"`,
					image: '',
					name: 'Pravin Pisal',
					institute: ''
				},
				{
					content:
						'Very nice app.... Helps to connect techers with parents and students. And make things easier ... Highly recommend',
					image: '',
					name: 'Shahbaz Khan',
					institute: ''
				},
				{
					content:
						' Great app. It not only assists parent and teachers but acts as a great boon for all the students out there by providing online support for tutorials, assignment and lectures. Would recommend it to be downloaded by all those, who want their kids to excel in academics, and all those students who wants to have sharper edge on their classmates. ',
					image: '',
					name: 'Laxman Raut',
					institute: ''
				},
				{
					content:
						' One of the best educational apps. It provides with a lot of features which enable a smooth and easy learning experience. The feature of live classes, assignments, homework, videos..all help me to learn and improve my academic performance. My parents also check my performance status from time to time.',
					image: '',
					name: 'Partha Thakur',
					institute: ''
				},
				{
					content:
						'All in one app , students attendance , classwork , homework , online exams , emergency text , results, live classes etc .It is very useful application for students and parents.',
					image: '',
					name: 'Ganesh Raut',
					institute: ''
				}
			];
		} else if (isStudent) {
			data = [
				{
					content: `This is a very useful and nice app for students and teachers in these difficult times. It has tests, assignments, etc. and we can also monitor our performance. It has some amazing features like chat with your teacher, records of number of classes conducted and your attendance in each class. `,
					image: '',
					name: 'Rohit',
					institute: ''
				},
				{
					content:
						'It’s a very nice app. I study in Khandelwal institute and due to lockdown I can continue my classes from this app. There is a lot of study material in this app. Study videos are uploaded every day. There are also live classes every day. Attendance, Assignments, tests are also been taken. That’s why I choose this app.',
					image: '',
					name: 'Kalyani Nadhgav',
					institute: ''
				},
				{
					content:
						"This app is like a blessing for all students. The faculty is very dedicated towards the students and teach every single detail like they do in physical class. In today's era due to lockdown and Corona, the app is helping students in a great manner.Best app for online studies in lockdown ",
					image: '',
					name: 'Chetya Salunkhe',
					institute: ''
				},
				{
					content:
						' I would highly recommend this app to every student out there as it is just awesome! It provides you one of the best user-friendly interface and organized content. Moreover, the feature of live chat with other online classmates makes it more interactive. And the best part is we get personalized reports , which I think is very important to monitor our performance',
					image: '',
					name: 'Chakula Lore',
					institute: ''
				},
				{
					content:
						'One of the best educational apps. It provides with a lot of features which enable a smooth and easy learning experience. The feature of live classes, assignments, homework, videos - all helped me to learn and improve my academic performance. My parents also check my performance status from time to time',
					image: '',
					name: 'Partha Thakur',
					institute: ''
				}
			];
		} else {
			data = [
				{
					content: `मै दीपांकर सचान, सचान क्लासेस प्रयागराज से मुझे क्लास प्लस ने लोकल से ग्लोबल कर दिया । शानदार एप। बहुत अच्छा। जैसे ही लॉक डाउन हुआ मैंने तो सोचा ही नहीं था कि कुछ होगा भी ऐसे में क्लास प्लस से विपुल जी आते है देवदूत बनकर और बनवा देते है दुनियां को बदल देने एप ,"सचान क्लासेस" रियली मजा आ रहा है। ऑनलाइन एजुकेशन देते हुए। थैंक्स classPlus"`,
					image:
						'https://lh3.googleusercontent.com/a-/AOh14Gj8ecGpNbDvxnO28z6ifRnpvla9DxDZLg1tSvkK=w96-h96-n-rw',
					name: 'Deepankar Sachan',
					institute: 'SACHAN Classes'
				},
				{
					content:
						'The new admissions are always impressed by our coaching centres on the application. The user experience of the platform is very smooth and effective. A lot of Many of the parents install the app after visiting the institute. The app has been highly effective.',
					image:
						'https://res.cloudinary.com/classplus/image/upload/v1528711834/profile_images/fvreti9abhfeom5nyaqw.jpg',
					name: 'Dhiraj Pahade',
					institute: 'Base Tutorials'
				},
				{
					content:
						' I am Ashu Mehta. I am very happy and very satisfied with class plus . CLASSPLUS ने मेरा सपना पूरा किया है। App:MISSION DSSSB इन्ही की देन हैं जो आये दिन नए कीर्तिमान स्थापित कर रही है ।',
					image:
						' https://lh3.googleusercontent.com/a-/AOh14GhzQRJ4igj8Sa-aiPs8XeC2pIi0jpgQxJvW3PeXJg=w96-h96-n-rw',
					name: 'Ashu Mehta',
					institute: 'MISSION DSSSB'
				},
				{
					content:
						' Few of the main requirements to successfully run a coaching centre are fee management, attendance record, distribution of notes and assignments to students and most importantly staying in constant touch with the parents. Using Classplus, we can monitor all of these in a digital way. There is nothing better.',
					image:
						'https://res.cloudinary.com/classplus/image/upload/v1522121972/profile_images/w3zcy6vwketrd5kpjmg8.jpg',
					name: 'Harish Tutani',
					institute: 'Tulsan Education'
				},
				{
					content:
						"The app has been extremely helpful for me in every sense. It's features like attendance, notices and test marks are praised by all students and I am also very happy with the app’s performance. This app has sold solved a lot of my problems. We have received great response from students",
					image: user,
					name: 'Ritesh Bhujader',
					institute: 'Ruchi Tutorials'
				}
			];
		}
		return (
			<div style={{ padding: '60px 0', background: '#ffffff' }}>
				<Carousel
					elements={data.map((dt) => <TestCard data={dt} />)}
					dotsDisabled={false}
					width="100%"
					elementWidth={window.screen.width}
					activeSlide={0}
					head={
						<div className="major-div">
							<div className="major-heading">
								{isParent ? (
									'Hear from the parents who love us'
								) : isStudent ? (
									'Hear from the students who love us'
								) : (
									'From the people who love us'
								)}
							</div>
							<div className="major-subheading">
								{isParent ? (
									"Your child performs better when you are a part of their education. Here's why parents love us."
								) : isStudent ? (
									"Students from all over the country have reconnected with their teachers using Classplus Lite mobile app. Here's why they love us. "
								) : (
									'We understand all the struggles of a teacher, the difficulties that a coaching center owner faces in starting and in running their business. We understand. We empathise.'
								)}
							</div>
						</div>
					}
				/>
			</div>
		);
	}
}

export default Testimonial;
