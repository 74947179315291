import React from 'react';
import tick from "../images/tick.svg";
import communicate from "../images/communicate.svg";
import teach from "../images/teach.svg";

export const PointerSection = () => {
    return (
        <div className="pointer-topDiv">
            <div className="pointer-firstbox">
                    <img src={teach} alt="teach"/>
                    <div className="flex-class-column">
                        <p className="pointer-heading">Teach</p>
                        <p className="pointer-content">Take your classroom online in under 2 minutes and start teaching your students.</p>
                        <div className="pointer-list-div">
                                <div className="flex-class-row">
                                    <img src={tick} alt="tick" style={{marginRight:"14px"}}/>
                                    <p className="pointer-point">Create and Manage Batches</p>
                                </div>
                                <div className="flex-class-row">
                                    <img src={tick} alt="tick" style={{marginRight:"14px"}}/>
                                    <p className="pointer-point">Conduct Live Classes</p>
                                </div>
                                <div className="flex-class-row">
                                    <img src={tick} alt="tick" style={{marginRight:"14px"}}/>
                                    <p className="pointer-point">Share Assignments & Study Material</p>
                                </div>
                                <div className="flex-class-row">
                                    <img src={tick} alt="tick" style={{marginRight:"14px"}}/>
                                    <p className="pointer-point">Take Online Tests</p>
                                </div>
                        </div>
                    </div>

            </div>
            <div className="pointer-secondbox">
                    <div className="flex-class-column">
                        <p className="pointer-heading">Communicate</p>
                        <p className="pointer-content">Chat and communicate with students, parents and faculty all from one place.</p>
                        <div className="pointer-list-div">
                            <div className="flex-class-row">
                                <img src={tick} alt="tick" style={{marginRight:"14px"}}/>
                                <p className="pointer-point">Create Custom Chat Groups</p>
                            </div>
                            <div className="flex-class-row">
                                <img src={tick} alt="tick" style={{marginRight:"14px"}}/>
                                <p className="pointer-point">Send Announcements</p>
                            </div>
                        </div>
                    </div>
                    <img src={communicate} alt="communicate"/>

            </div>
        </div>
    )
}

export default PointerSection