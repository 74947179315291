import React, { Component, useState } from 'react';
import QuestionBox from './QuestionBox';
function GettingStarted() {
	// constructor(){
	//     super();
	//     this.state={
	const [ data, setData ] = useState([
		{
			clicked: false,
			ques: `How can I migrate my coaching operations online ?`,
			ans: `We are here to take care of everything :) We want you to focus on teaching and inspiring minds.`
		},
		{
			clicked: false,
			ques: `Is the product free to use ?`,
			ans: `Yes, it is free for lifetime! `
		},

		{
			clicked: false,
			ques: `Who all can use the app ?`,
			ans: `You can invite students, parents and faculty to use the application. Access and functionality between these three roles can be controlled from within the app.`
		},

		{
			clicked: false,
			ques: `What can I do using the product ?`,
			ans: `Our goal is to help you take your coaching business completely online. In the current version you can add and manage batches of students, conduct live classes, share study material in the form of videos, homeworks, communicate with students, parents and faculty and much more.`
		}
	]);
	// let [length,setLength]=useState(0);

	const handleClicked = (index) => {
		setData(
			data.map((item, i) => {
				if (i === index) {
					item.clicked = !item.clicked;
				} else {
					item.clicked = false;
				}
				return item;
			})
		);
	};

	return (
		<div style={{ padding: '60px 0 150px 0', width: '100%' }}>
			{data.map((dt, index) => {
				return <QuestionBox data={dt} index={index} length={data.length} handleClicked={handleClicked} />;
			})}
		</div>
	);
}

export default GettingStarted;
