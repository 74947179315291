import React, { Component } from "react";
import IN from "../images/in.svg";
import close from "../images/close.svg";
import Success from "../images/Animation.gif";

class ReferralModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileValue: "",
      isMobileErrorMsg: false,
      isMobileValid: false,
      ButtonEnable: false,
      ButtonColor: "#E5E5E5",
      success: false,
      xAccessToken:
        "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDU0MjE2OCwib3JnSWQiOjEyMjksInR5cGUiOjMsIm1vYmlsZSI6IjkxOTU5OTI0MTkwMCIsIm5hbWUiOiJCSU5OTyIsImVtYWlsIjoidGVza2poZ3pzZHhjZmdoYmpua21Aa2poZ2ZjZ3ZiaGpua20uY29uIiwiaWF0IjoxNTk5MjE3Mjk2LCJleHAiOjE2MDA5NDUyOTZ9.RzPOtJ8iLLQ_KBRiNZCZoRJ7yJaid4G4CMESFl-8Ay8eIhazUy7MFHRxMgg9eNSY",
    };
  }

  // componentDidMount() {
  //   fetch("http://dev-testing.classplus.co/v2/freemium/referral/sms", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-access-token": this.getQuery()["token"],
  //     },
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       if (data.status == "failure") {
  //         return alert(
  //           `${data.message} ${
  //             data.data.message ? `: ${data.data.message}` : ""
  //           }`
  //         );
  //       } else {
  //         return this.setState({ message: data.data.whatsAppText });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }

  validation = () => {
    const { isMobileValid, mobileValue } = this.state;
    if (isMobileValid === true && mobileValue !== "") {
      this.setState({
        ButtonEnable: true,
        ButtonColor: "#009ae0",
      });
    } else {
      this.setState({
        ButtonEnable: false,
        ButtonColor: "#E5E5E5",
      });
    }
  };

  mobileValueSet = (event) => {
    let mobileFormat = new RegExp(/^[6-9]$/);
    if (event.target.value.length <= 10) {
      this.setState(
        {
          mobileValue: event.target.value.replace(/\D/, ""),
          isMobileValid: true,
        },
        () => {
          this.validation();
        }
      );
    }
    if (mobileFormat.test(event.target.value.charAt(0))) {
      this.setState(
        {
          isMobileValid: true,
          isMobileErrorMsg: false,
        },
        () => {
          this.validation();
        }
      );
    } else {
      this.setState(
        {
          isMobileValid: false,
          isMobileErrorMsg: true,
        },
        () => {
          this.validation();
        }
      );
    }
    if (event.target.value.length === 10) {
      this.setState(
        {
          isMobileErrorMsg: false,
        },
        () => {
          this.validation();
        }
      );
    }
  };

  mobileError = (event) => {
    if (event.target.value.length < 10) {
      this.setState(
        {
          isMobileErrorMsg: true,

          isMobileValid: false,
        },
        () => {
          this.validation();
        }
      );
    } else if (event.target.value.length === 10) {
      this.setState(
        {
          isMobileErrorMsg: false,
        },
        () => {
          this.validation();
        }
      );
    }
  };

  referralApiCall = () => {
    const { xAccessToken, mobileValue } = this.state;
    let payload = {
      mobile: mobileValue,
    };
    fetch(`https://apip.classplusapp.com/v2/freemium/referral/sms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.getQuery()["token"],
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ success: true });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split("&")
      .map((qStr) => qStr.split("="))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});
    return b;
  };

  goBackToRefer = () => {
    this.setState({ success: false, mobileValue: "" });
  };

  render() {
    const { mobileValue, success, ButtonColor, ButtonEnable } = this.state;
    return (
      <div className="ReferralModal">
        <div className="ReferralModal__Modal">
          {!success ? (
            <div className="ReferralModal__Modal--ModalContainer">
              <div className="ReferralModal__Modal--ModalContainer__Header">
                <div className="ReferralModal__Modal--ModalContainer__Header--r1">
                  <label>Refer your tutor friends!</label>
                  <img
                    alt="close"
                    onClick={this.props.closeModal}
                    style={{ width: "3rem", height: "3rem" }}
                    src={close}
                  />
                </div>
                <div className="ReferralModal__Modal--ModalContainer__Header--r2">
                  Enter the mobile number of your tutor friends to win exciting
                  prizes
                </div>
              </div>
              <div className="ReferralModal__Modal--ModalContainer__Body">
                <div className="ReferralModal__Modal--ModalContainer__Body--r1">
                  <div className="ReferralModal__Modal--ModalContainer__Body--r1__MobileHead">
                    Enter Mobile Number
                  </div>
                  <div className="ReferralModal__Modal--ModalContainer__Body--r1__MobileNumber">
                    <img className="IN" src={IN} alt="IN" />
                    <input
                      id="mobile"
                      value={mobileValue}
                      type="number"
                      onChange={this.mobileValueSet}
                      pattern="[0-9]*"
                      placeholder="eg. 9898989898"
                      onBlur={this.mobileError}
                    />
                  </div>
                  {this.state.isMobileErrorMsg === true ? (
                    <div className="ReferralModal__Modal--ModalContainer__Body--r1__MobileError">
                      * Mobile Number Not Valid
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="ReferralModal__Modal--ModalContainer__Body--r2">
                  <button
                    onClick={this.referralApiCall}
                    disabled={!ButtonEnable}
                    style={{ background: ButtonColor }}
                    className="ReferralModal__Modal--ModalContainer__Body--r2__Button"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="ReferralModal__Modal--ModalContainerS">
              <div className="ReferralModal__Modal--ModalContainerS__Head">
                <label style={{ color: "#ffffff" }}>HIDE</label>
                <img
                  alt="gif"
                  style={{ width: "30%", height: "30%" }}
                  src={Success}
                />
                <img
                  alt="close"
                  style={{ width: "3rem", height: "3rem" }}
                  src={close}
                  onClick={this.props.closeModal}
                />
              </div>
              <div className="ReferralModal__Modal--ModalContainerS__Thanks">
                Thank You!
              </div>
              <div className="ReferralModal__Modal--ModalContainerS__SMS">
                आपके टुटर साथी को अप्प का डाउनलोड लिंक SMS करदिया गया है{" "}
              </div>
              <div className="ReferralModal__Modal--ModalContainerS__Button">
                <button
                  onClick={this.goBackToRefer}
                  className="ReferralModal__Modal--ModalContainerS__Button--btn"
                >
                  Refer Another Tutor
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ReferralModal;
