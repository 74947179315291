import React, { Component } from "react";
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import TnC from "./components/TnC";
import ParentLanding from "./components/ParentLanding";
import Policy from "./components/Policy";
import ScrollToTop from "./ScrollToTop";
import SeeMore from "./components/SeeMore";
import StudentLanding from "./components/StudentLanding";
import Referral from "./components/Referral";
import Referral2 from "./components/Referral2";
import "./App.scss";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

class App extends Component {
  constructor() {
    super();
    this.state = {
      showNavigation: true,
    };
  }

  render() {
    return (
      <div className="App fade-in">
        <BrowserRouter basename="/lp/my-classroom">
          {window.location.href.includes("/referral") ||
          window.location.href.includes("/referral2") ? (
            ""
          ) : (
            <Navigation />
          )}
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              {/* <Route exact path="/features" component={DetailedFeatures} /> */}
              <Route exact path="/tnc" component={TnC} />
              <Route exact path="/parents" component={ParentLanding} />
              <Route exact path="/policy" component={Policy} />
              <Route exact path="/faq" component={SeeMore} />
              <Route exact path="/students" component={StudentLanding} />
              <Route exact path="/referral" component={Referral} />
              <Route exact path="/referral2" component={Referral2} />

              <Route render={() => <Redirect to="/" />} />
            </Switch>
            {window.location.href.includes("/referral") ||
            window.location.href.includes("/referral2") ? (
              ""
            ) : (
              <Footer />
            )}
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
