import React, { Component, useState } from 'react';
import QuestionBox from './QuestionBox';
function AssignmentQuestions() {
	const [ data, setData ] = useState([
		{
			clicked: false,
			ques: `How to assign homework?`,
			ans: `To assign homework:
                    1. On the Batches tab, click & choose the batch you want to assign homework.
                    2. In Homework Tab, click on Assign homework.
                    3. Click on “+” icon to add students & select the students.
                    4. Enter the topic & submission date.
                    5. Add attachment you want to send to students & click Done.
                    `
		},
		{
			clicked: false,
			ques: `How to check homework status?`,
			ans: `To check homework status:
                    1. Click & choose the batch you want to check status
                    2. Click on Homework tab & there you can check the submission status.
                    3. Check individual student status along with attachment by clicking on names.
                    4. You can also edit individual status & mark it as done.`
		}
	]);
	const handleClicked = (index) => {
		setData(
			data.map((item, i) => {
				if (i === index) {
					item.clicked = !item.clicked;
				} else {
					item.clicked = false;
				}
				return item;
			})
		);
	};

	return (
		<div style={{ padding: '60px 0 150px 0' }}>
			{data.map((dt, index) => {
				return <QuestionBox data={dt} index={index} length={data.length} handleClicked={handleClicked} />;
			})}
		</div>
	);
}

export default AssignmentQuestions;
