import React from "react";
import "./Tabs.css";
// import { withAccessControl } from '../../../_HOC/WithAccessControl';
// import { Link, withRouter } from 'react-router-dom';
class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
       tabsArray,
      selectedTabId,
      selectTab,
      component: C,
    } = this.props;

    return (
      <div className="TabContainer">
        <div className="TabsHeader">
          <div style={{ display: "flex", width: "100%" }}>
            {tabsArray.map((tab) => (
              <div
                className={`Tab ${tab.id === selectedTabId && "Selected"}`}
                onClick={() => {
                  selectTab(tab.id);
                }}
                key={tab.id}
              >
                {tab.label}
              </div>
            ))}
          </div>
        </div>
        <C
          tab={tabsArray.find((tab) => tab.id === selectedTabId)}
        />
      </div>
    );
  }
}

export default Tabs;
