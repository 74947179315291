import React from 'react';

export const TnC = () => {
	return (
		<div class="tnc">
			<div class="tnc__heading">TERMS OF SERVICE</div>
			<div class="tnc__list">
				<div class="tnc__item">
					<div class="tnc__item--title">1. Terms</div>
					<div class="tnc__item--content">
						By accessing the website at http://www.mycoaching.live, you are agreeing to be bound by these
						terms of service, all applicable laws and regulations, and agree that you are responsible for
						compliance with any applicable local laws. If you do not agree with any of these terms, you are
						prohibited from using or accessing this site. The materials contained in this website are
						protected by applicable copyright and trademark law.
					</div>
				</div>

				<div class="tnc__item">
					<div class="tnc__item--title">2. Use License</div>
					<div class="tnc__item--content">
						Permission is granted to temporarily download one copy of the materials (information or
						software) on Bunch Microtechnologies Private Limited's website for personal, non-commercial
						transitory viewing only. This is the grant of a license, not a transfer of title, and under this
						license you may not: modify or copy the materials; use the materials for any commercial purpose,
						or for any public display (commercial or non-commercial); attempt to decompile or reverse
						engineer any software contained on Bunch Microtechnologies Private Limited's website; remove any
						copyright or other proprietary notations from the materials; or transfer the materials to
						another person or "mirror" the materials on any other server. This license shall automatically
						terminate if you violate any of these restrictions and may be terminated by Bunch
						Microtechnologies Private Limited at any time. Upon terminating your viewing of these materials
						or upon the termination of this license, you must destroy any downloaded materials in your
						possession whether in electronic or printed format.
					</div>
				</div>

				<div class="tnc__item">
					<div class="tnc__item--title">3. Disclaimer</div>
					<div class="tnc__item--content">
						The materials on Bunch Microtechnologies Private Limited's website are provided on an 'as is'
						basis. Bunch Microtechnologies Private Limited makes no warranties, expressed or implied, and
						hereby disclaims and negates all other warranties including, without limitation, implied
						warranties or conditions of merchantability, fitness for a particular purpose, or
						non-infringement of intellectual property or other violation of rights. Further, Bunch
						Microtechnologies Private Limited does not warrant or make any representations concerning the
						accuracy, likely results, or reliability of the use of the materials on its website or otherwise
						relating to such materials or on any sites linked to this site.
					</div>
				</div>

				<div class="tnc__item">
					<div class="tnc__item--title">4. Limitations</div>
					<div class="tnc__item--content">
						In no event shall Bunch Microtechnologies Private Limited or its suppliers be liable for any
						damages (including, without limitation, damages for loss of data or profit, or due to business
						interruption) arising out of the use or inability to use the materials on Bunch
						Microtechnologies Private Limited's website, even if Bunch Microtechnologies Private Limited or
						a Bunch Microtechnologies Private Limited authorized representative has been notified orally or
						in writing of the possibility of such damage. Because some jurisdictions do not allow
						limitations on implied warranties, or limitations of liability for consequential or incidental
						damages, these limitations may not apply to you.
					</div>
				</div>

				<div class="tnc__item">
					<div class="tnc__item--title">5. Accuracy of materials</div>
					<div class="tnc__item--content">
						The materials appearing on Bunch Microtechnologies Private Limited's website could include
						technical, typographical, or photographic errors. Bunch Microtechnologies Private Limited does
						not warrant that any of the materials on its website are accurate, complete or current. Bunch
						Microtechnologies Private Limited may make changes to the materials contained on its website at
						any time without notice. However Bunch Microtechnologies Private Limited does not make any
						commitment to update the materials.
					</div>
				</div>

				<div class="tnc__item">
					<div class="tnc__item--title">6. Links</div>
					<div class="tnc__item--content">
						Bunch Microtechnologies Private Limited has not reviewed all of the sites linked to its website
						and is not responsible for the contents of any such linked site. The inclusion of any link does
						not imply endorsement by Bunch Microtechnologies Private Limited of the site. Use of any such
						linked website is at the user's own risk.
					</div>
				</div>

				<div class="tnc__item">
					<div class="tnc__item--title">7. Modifications</div>
					<div class="tnc__item--content">
						Bunch Microtechnologies Private Limited may revise these terms of service for its website at any
						time without notice. By using this website you are agreeing to be bound by the then current
						version of these terms of service.
					</div>
				</div>

				<div class="tnc__item">
					<div class="tnc__item--title">8. Governing Law</div>
					<div class="tnc__item--content">
						These terms and conditions are governed by and construed in accordance with the laws of Delhi
						and you irrevocably submit to the exclusive jurisdiction of the courts in that State or
						location.
					</div>
				</div>

				<div class="tnc__item">
					<div class="tnc__item--title">9. Refund Policy</div>
					<div class="tnc__item--content">
						No Early Termination; No Refunds. The Subscription Term will end on the expiration date and you
						cannot cancel it before its expiration. We do not provide refunds if you decide to stop using
						the mycoaching subscription during your Subscription Term.
					</div>
				</div>
			</div>
		</div>
	);
};

export default TnC;
