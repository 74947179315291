import React,{Component,useState} from 'react';
import QuestionBox from "./QuestionBox"
function ChatQuestions(){
   const [data,setData]=useState([
            {clicked:false,
              ques:`How to message/chat with a student?`,
              ans:`To start a chat with a student:
                  1. Click on “CHATS”  tab in the navigation panel on the bottom.
                  2. Tap on “+” icon to Start a conversation.
                  3. Type & search the name of a student you wish to chat with.
                  4. Type your message & click on Send option.  
`
            },
            {clicked:false,
              ques:`How to initiate a chat with parents?`,
              ans:`To start a chat with parents:
                  1. On the Batches tab, click & choose the batch of a particular student.
                  2. Under the “STUDENTS” tab, click on the student’s name.
                  3. Tap on the chat icon associated with parent’s detail.
                  4. Type your message & click on Send option.  
`
            },
             {clicked:false,
              ques:`How to use the broadcast feature?`,
              ans:`To use the broadcast feature in chats:
                  1. Click into “CHATS” tab in the navigation panel on the bottom.
                  2. Tap on “Start a broadcast”.
                  3. Select multiple students / individual batch to send a broadcast message.
                  4. Type the broadcast message & add attachments, if any and Send.

                  `     
                  }
            ,
            {clicked:false,
              ques:`How to turn on/off replies of students?`,
              ans:`To turn on/off the replies:
                    1. Click into “CHATS” tab in the navigation panel on the bottom.
                    2. Tap & choose student’s name you wish to turn off replies.
                    3. Click on the notification icon on the top-right corner.
                    4. Turn off the toggle button by making it Grey.
                    `
            },

            {clicked:false,
              ques:`How to clear a conversation?`,
              ans:`To delete full chats / individual messages:
                  1. Click into “CHATS” tab in the navigation panel on the bottom.
                  2. Select for any chat / individual message and click on Delete.

                    `
            },

            {clicked:false,
              ques:`How to copy a chat message?`,
              ans:`To copy individual / multiple chat message:
                    1. Click into “CHATS” tab in the navigation panel on the bottom.
                    2. Choose for individual or multiple chat messages to copy.
                    3. Copy the messages by clicking on the 3-dots option above. 

                    `
            }
            
          ]);

                  const handleClicked =index=>{
        setData(data.map((item,i)=>{
            if(i===index){
              item.clicked=!item.clicked
            }
            else{
              item.clicked=false
            }
          return item
        })
        )
    }

    return (
      <div style={{padding:"60px 0 150px 0"}}>
      {data.map((dt,index)=>{
          return(
            <QuestionBox data={dt} index={index} length={data.length} handleClicked={handleClicked}/>
          )
          })
      }
      </div>
    )
}

export default ChatQuestions