import React, { useState } from 'react';
import QuestionBox from './QuestionBox';
function StudentQuestion() {
	const [ data, setData ] = useState([
		// {clicked:false,
		//   ques:`How to view Assigned Tests?`,
		//   ans:`To view assigned tests:
		//        1. On the Batches tab, click & choose the batch you want to view.
		//        2. Under “TESTS” Tab you will get the list of offline & online tests conducted by the tutor.

		//         `
		// },
		// {clicked:false,
		//   ques:`How to attempt online test?`,
		//   ans:`To attempt online tests:
		//         1. On the Batches tab, click & choose the batch.
		//         2. Click on TESTS option & tap on the test assigned to attempt.
		//         `
		// },
		{
			clicked: false,
			ques: `How to chat with a tutor?`,
			ans: `To chat with a tutor:
                    1. Click into “CHATS” tab in the navigation panel on the bottom.
                    2. Click on “+” icon on the bottom right corner.
                    3. Type your message for the tutor & click on Send.`
		},
		// {clicked:false,
		//   ques:`How to check attendance?`,
		//   ans:`To check attendance:
		//         1. On the Batches tab, click & choose the batch.
		//         2. Tap on Attendance Tab & check your attendance.`
		// },
		{
			clicked: false,
			ques: `How to check notice?`,
			ans: `To check notice:
                    1. On the Batches tab, click & choose the batch
                    2. Under the Recent Notice section, you will be able to see recent notices made by your tutor.
                    3. To check previous notices click on View All option.`
		},
		// {clicked:false,
		//   ques:`How to check marks of online tests?`,
		//   ans:`You will get the reports of the attempted online tests. To check marks of online tests:
		//         1. Click on the Reports tab in the navigation panel.
		//         2. Select the type of test as Online.`
		// },
		{
			clicked: false,
			ques: `How to get added to batch using batch code?`,
			ans: `To get added to batch using batch code:
                    1. Click on the “+” icon in the batch tab.
                    2. Enter the Batch Code & click on Ok to send request to the batch.`
		}
	]);

	const handleClicked = (index) => {
		setData(
			data.map((item, i) => {
				if (i === index) {
					item.clicked = !item.clicked;
				} else {
					item.clicked = false;
				}
				return item;
			})
		);
	};
	return (
		<div style={{ padding: '60px 0 150px 0', width: '100%' }}>
			{data.map((dt, index) => {
				return <QuestionBox data={dt} index={index} length={data.length} handleClicked={handleClicked} />;
			})}
		</div>
	);
}

export default StudentQuestion;
