import React, { Component } from 'react';
import add from '../images/add.svg';
class QuestionBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// clicked:false
		};
	}

	// handleClicked=()=>{
	//     this.setState({clicked:!this.state.clicked})
	// }

	render() {
		let { index, length, image, noBorder, noPadding } = this.props;
		return (
			<div
				className="question-box"
				style={{
					borderBottom: `${length === index + 1 ? '1px solid #e5e5e5' : ''}`,
					borderTop: `${index == 0 && noBorder ? '' : '1px solid #e5e5e5'}`,
					borderLeft: `${noBorder ? '' : '1px solid #e5e5e5'}`,
					borderRight: `${noBorder ? '' : '1px solid #e5e5e5'}`
				}}
			>
				<div
					className="question-firstdiv"
					onClick={() => this.props.handleClicked(index)}
					style={{
						borderBottom: `${this.props.data.clicked ? '1px solid #e5e5e5' : ''}`,
						padding: `${window.screen.width < 1000 ? (noPadding ? '16px 0px' : '16px') : '24px 32px'}`
					}}
				>
					<p className="question-firstline">{this.props.data.ques}</p>
					<img src={image ? image : add} alt="" />
				</div>
				{this.props.data.clicked && (
					<p
						className="question-secondline"
						style={{
							padding: `${window.screen.width < 1000 ? (noPadding ? '16px 0px' : '16px') : '24px 32px'}`
						}}
					>
						{this.props.data.ans}
					</p>
				)}
			</div>
		);
	}
}

export default QuestionBox;
