import React, { Component } from 'react';
import ImageCard from './ImageCard';
import Carousel from '../UI/Carousel/Carousel';
import user from '../../src/images/user.svg';
import scroll1 from '../images/scroll1.jpg';
import scroll2 from '../images/scroll2.jpg';
import scroll3 from '../images/scroll3.jpg';
import scroll4 from '../images/scroll4.jpg';
import scroll5 from '../images/scroll5.jpg';
import scroll6 from '../images/scroll6.jpg';
import scroll7 from '../images/scroll7.jpg';
import scroll8 from '../images/scroll8.jpg';
class TestimonialImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [
				{
					image: scroll1
				},
				{
					image: scroll2
				},
				{
					image: scroll3
				},
				{
					image: scroll4
				},
				{
					image: scroll5
				},
				{
					image: scroll6
				},
				{
					image: scroll7
				},
				{
					image: scroll8
				}
			]
		};
	}

	render() {
		const { data } = this.state;
		const { isStudent } = this.props;
		return (
			<div style={{ padding: '40px 0', background: '#ffffff' }}>
				<Carousel
					elements={data.map((dt) => <ImageCard data={dt} />)}
					dotsDisabled={false}
					width="100%"
					elementWidth={window.screen.width}
					activeSlide={0}
					head={
						<div style={{ fontSize: '28px', lineHeight: '34px', textAlign: 'center' }}>
							{isStudent ? 'How can your teachers use this?' : 'How can tutors use this?'}
						</div>
					}
				/>
			</div>
		);
	}
}

export default TestimonialImage;
