import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class FrequentQuestions extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// handleMoreClick =()=>{
	//     console.log("new")
	// }

	render() {
		return (
			<div className="frequest" style={{ background: '#ffffff' }}>
				<p className="frequent-heading">Frequently asked questions</p>
				<p className="frequent-subheading">
					If you’re new to here, then this will help you learn more about the platform and its features.
				</p>

				<div className="frequent-grid">
					<div className="frequent-grid-item">
						<p className="frequent-grid-item-head">How can I migrate my coaching operations online ?</p>
						<p className="frequent-grid-item-content">
							We are here to take care of everything :) We want you to focus on teaching and inspiring
							minds.
						</p>
					</div>

					<div className="frequent-grid-item">
						<p className="frequent-grid-item-head">Is the product free to use ?</p>
						<p className="frequent-grid-item-content">Yes, it is free for lifetime!</p>
					</div>

					<div className="frequent-grid-item">
						<p className="frequent-grid-item-head">Who all can use the app ?</p>
						<p className="frequent-grid-item-content">
							You can invite students, parents and faculty to use the application. Access and
							functionality between these three roles can be controlled from within the app.
						</p>
					</div>

					<div className="frequent-grid-item">
						<p className="frequent-grid-item-head">What can I do using the product ? </p>
						<p className="frequent-grid-item-content">
							Our goal is to help you take your coaching business completely online. In the current
							version you can add and manage batches of students, conduct live classes, share study
							material in the form of videos, homeworks, communicate with students, parents and faculty
							and much more.
						</p>
					</div>
				</div>
				<div className="see-more-div">
					<Link to="/faq" className="see-more">
						See More FAQ's
					</Link>
				</div>
			</div>
		);
	}
}

export default FrequentQuestions;
