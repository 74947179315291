import React, { Component } from 'react';
import Form from './Form';
import topImage from '../images/illus.png';
import downArrow from '../images/downArrow.svg';
// import formLogo from "../images/formLogo.svg"
import formLogo from '../images/Classplus-Lite-footer.svg';
class Landing extends Component {
	constructor() {
		super();
		this.state = {
			width: window.innerWidth
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		// fetch('https://skynet.classplusapp.com/api/v1/redirect/lead-image/book-a-demo-landing-image',{
		//     method:'GET',?utm_source=google&utm_medium=email&utm_campaign=kuchBhi

		// }).then(
		//     this.setState({
		//         imageUrl:
		//     })
		// )
	}

	updateDimensions = () => {
		this.setState({
			width: window.innerWidth
		});
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions());
	}
	render() {
		const background = () => {
			if (window.innerWidth < 480) {
				return {
					// backgroundImage: `url(${CoronaBackgroundMob})`,
					width: '100%',
					backgroundSize: 'contain'
				};
			} else {
				return {
					// backgroundImage: `url(${BackgroundImageNew})`,
					backgroundPosition: 'inherit',
					width: '100%',
					backgroundSize: 'cover'
				};
			}
		};

		return (
			<div className="landing-top-flex">
				<div className="landing grid--1">
					<div className="landing__content topDiv-padding">
						<div className="landing__content--sub topDivHeading">
							Take your classroom online in<span style={{ color: '#009AE0' }}> less than 60 seconds</span>
						</div>

						<p className=" topDivSubheading ">
							Run your coaching institute, digitally, from the palm of your hands.
							<span style={{ color: '#009AE0' }}> Free for life.</span>
						</p>

						<img src={topImage} alt="topImage" className="landing-topImage" />
					</div>

					<div className="mobile-down-arrow">
						<img src={downArrow} alt="down" />
					</div>

					<div className="landing__form form-basic">
						<div className="landing__form--header">
							<img src={formLogo} alt="formLogo" style={{ margin: '0 32px 16px 32px', width: '180px' }} />
							<div className="landing__form--header--heading">
								Get Your <span style={{ color: '#25D366' }}>FREE</span> Teaching App
							</div>
							<div className="landing__form--header--content green--text" />
						</div>
						<div>
							<Form source="landing" />
						</div>
					</div>
				</div>
				<div className="web-down-arrow">
					<img src={downArrow} alt="down" />
				</div>
				<div className="landing-embrace">
					<p className="landing-embrace-heading" style={{ marginBottom: '4px' }}>
						Aatmanirbhar Banein
					</p>
					<p style={{ color: 'rgb(0, 154, 224)', fontSize: '24px', lineHeight: '27px' }}>
						#aatmanirbharbharat
					</p>
					<p className="landing-embrace-subheading">
						One platform with all the features that you will need to start, run, and grow your coaching
						institute’s business.
					</p>
				</div>
			</div>
		);
	}
}

export default Landing;
