import React, { Component } from 'react';
import image1 from '../images/image1.svg';
import image2 from '../images/image2.svg';
import image3 from '../images/image3.svg';
import referCam from '../images/referCam.svg';
import referCard from '../images/referCard.svg';
import referSend from '../images/referSend.svg';
import image4 from '../images/image4.svg';
import image5 from '../images/image5.svg';
import image6 from '../images/image6.svg';
import image7 from '../images/image7.svg';
import image8 from '../images/image8.svg';
import image9 from '../images/image9.svg';
import QuestionBox from './QuestionBox';
import plusBlue from '../images/plusBlue.svg';
import referVideoCover from '../images/refVid.svg';
import video from '../images/ReferVideo.m4v';
class Referral extends Component {
	constructor() {
		super();
		this.state = {
			message: '',
			data: [
				{
					clicked: false,
					ques: `Who should I refer?`,
					ans: `Any of your teacher friends or colleagues who want to begin taking their classes online. We advise you to help them through the whole process of signing up on the Classplus Lite App and adding their students too.`
				},
				{
					clicked: false,
					ques: ` Why should I become a part of the Refer & Win program?`,
					ans: `There are a lot of reasons why you should become an active part of the refer and win program. However, we feel the most important reason is to help us in our mission of ensuring every teacher is able to start teaching online. This would mean that every teacher has a digital identity, and that too, free of cost.`
				},
				{
					clicked: false,
					ques: ` What is a successful referral?`,
					ans: `A successful referral is when the referred teacher uses the link you shared to download the app, signup, and add a student, who then signs up on the Classplus Lite app using your referred teacher friend’s teacher code.`
				},
				{
					clicked: false,
					ques: ` How will I know if I won?`,
					ans: `We will announce all winners on the Classplus Lite App and also our social media channels (Facebook, Instagram and Twitter). We would advise you to begin following us on our social media channels. We will also be sending SMS, WhatsApp and calling the winners to tell them.`
				},
				{
					clicked: false,
					ques: `How can I become eligible for winning the jackpot prize?`,
					ans: `To be eligible for being considered for the jackpot prize, you need to make at least 6 *successful referrals. We will be deciding the winner based on the quality of the referrals i.e. how actively your friend, who you referred, uses the Classplus Lite app.`
				},
				{
					clicked: false,
					ques: `How can I become eligible for winning the weekly prize?`,
					ans: `To be eligible for being considered for the weekly prize, you just need to make at least one *successful referral in the week. The winner would again depend on the number of quality referrals that they make i.e. how actively your friend, who you referred, uses the Classplus Lite app.`
				}
			]
		};
	}

	componentDidMount() {
		fetch('https://apip.classplusapp.com/v2/freemium/text/referAndWin', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': this.getQuery()['token']
			}
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.status == 'failure') {
					return alert(`${data.message} ${data.data.message ? `: ${data.data.message}` : ''}`);
				} else {
					return this.setState({ message: data.data.whatsAppText });
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}

	getQuery = () => {
		let b = window.location.search.slice(1).split('&').map((qStr) => qStr.split('=')).reduce((acc, inc) => {
			acc[inc[0]] = inc[1];
			return acc;
		}, {});
		return b;
	};

	handleTandC = () => {
		window.open(
			`https://d2sqt6igbl7quy.cloudfront.net/cams/Terms+and+Conditions+-+Teachers'+Day+Referral.pdf`,
			'_blank'
		);
	};

	handleClick = () => {
		// const params = new URL(document.location).searchParams;
		// const user = params.get('user_id');
		// setTimeout(() => {
		// 	window.open(
		// 		`https://play.google.com/store/apps/details?id=my.classroom.app&referrer=utm_source%3Dtutor_reg%26anid%3DtutorReferral{${user}}`,
		// 		'_blank'
		// 	);
		// }, 100);
		window.open(`https://api.whatsapp.com/send?text=${this.state.message}`, '_blank');
	};

	setData = (item) => {
		this.setState({ clicked: item });
	};

	handleQuestionClicked = (index) => {
		this.setData(
			this.state.data.map((item, i) => {
				if (i === index) {
					item.clicked = !item.clicked;
				} else {
					item.clicked = false;
				}
				return item;
			})
		);
	};

	handleBlueShare = () => {
		if (navigator.share) {
			navigator
				.share({
					title: '',
					text: this.state.message,
					url: window.location.href
				})
				.then(() => {
					alert('Thanks for sharing!');
				})
				.catch((err) => {
					alert(`Couldn't share because of`, err.message);
				});
		} else {
			alert('web share not supported');
		}
	};

	render() {
		let { data } = this.state;
		return (
			<div className="referral">
				<img src={image1} alt="image1" style={{ width: '100%' }} />
				<div className="card">
					<div className="firstCard">
						{/* <img src={image4} style={{ marginBottom: '8px' }} /> */}
						<p className="firstcard-blackText">
							Apne <span className="firstcard-blueText ">teacher friends </span>
						</p>
						<p className="firstcard-blackText">
							ko humari teachers family ka hissa banayein,
							<p>
								aur <span className="firstcard-blueText">akarshak inaam </span>
								jitne ka mauka payein!
							</p>
						</p>
						<div className="firstcard-button-div">
							<button className="firstcard-referBtn" onClick={this.handleBlueShare}>
								<img src={referSend} alt="" style={{ marginRight: '12px' }} />
								REFER A TEACHER
							</button>
						</div>
						{/* <p className="firstCard-validity">Valid from: 5th to 14th September, 2020</p> */}
					</div>

					<div className="secondCard">
						<p className="secondCard-heading">Stand a chance to Win!</p>
						<div className="secondCard-div">
							<p className="secondCard-div-heading">
								On 6 successful* referrals one lucky winner every month will win the Jackpot Prize - A
								Coolmobiz 18 inch streaming kit to help take top-notch live classes.
							</p>
							<img src={referCam} style={{ marginLeft: '10px' }} />
						</div>
						<div className="secondCard-div" style={{ marginTop: '24px' }}>
							<img src={referCard} style={{ marginRight: '10px' }} />
							<p className="secondCard-div-heading">
								6 Lucky winners will get Amazon Gift Cards for successful* referrals EVERY WEEK! So you
								can pick what you want to gift yourself.
							</p>
						</div>
					</div>
					<div className="thirdCard">
						<div className="thirdCard-heading">
							<p className="thirdCard-heading-head">How it works</p>
							<button className="thirdCard-heading-tc" onClick={this.handleTandC}>
								Terms & conditions
							</button>
						</div>
						<div className="thirdCard-content">
							<img src={image5} />
							<p style={{ margin: '0px', padding: '12px 16px' }}>
								Share your referral link with your teacher friend using the "Refer" button
							</p>
						</div>
						<div className="thirdCard-content">
							<img src={image6} />
							<p style={{ margin: '0px', padding: '12px 16px' }}>
								Your referred teacher uses the link you shared to download the Classplus Lite app and
								Signup
							</p>
						</div>
						<div className="thirdCard-content">
							<img src={image7} />
							<p style={{ margin: '0px', padding: '12px 16px' }}>
								They share their teacher code with their students. Their student uses their teacher code
								to signup.
							</p>
						</div>
						<div className="thirdCard-content">
							<img src={image8} />
							<p style={{ margin: '0px', padding: '12px 16px' }}>
								Lookout for our pick of the 6 lucky winners every week and 1 jackpot winner every month.
							</p>
						</div>
						<div className="thirdCard-footer ">
							* A successful referral corresponds to the referred teacher using the link you shared to
							download the app, signup, adding a student, who then signs up on the Classplus Lite app
							using their teacher code.
						</div>
					</div>
					<div className="fourthCard">
						{/* <video
							src="https://www.youtube.com/watch?v=gbMjscni0_I&feature=youtu.be"
							controls
							width="100%"
							height="215"
							poster={referVideoCover}
            > */}

						{/* <source src="ReferVideo.m4v" type="video/m4v" /> */}
						{/* Sorry, your browser doesn't support embedded videos.
            </video> */}

						<iframe
							width="100%"
							height="215"
							src="https://www.youtube.com/embed/gbMjscni0_I"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						/>
					</div>
					<button className="firstcard-referBtn" onClick={this.handleBlueShare}>
						<img src={referSend} alt="" style={{ marginRight: '12px' }} />
						REFER A TEACHER
					</button>
					<div className="fifthCard">
						<p className="fifthCard-heading">Frequently Asked Questions</p>
						<div style={{ margin: '0 16px' }}>
							{data.map((dt, index) => {
								return (
									<QuestionBox
										data={dt}
										index={index}
										length={dt.length}
										handleClicked={this.handleQuestionClicked}
										image={plusBlue}
										noBorder={1}
										noPadding={1}
									/>
								);
							})}
						</div>
						<p className="fifthCard-footer">
							By participating in the refer and win program, you accept our terms and conditions and
							acknowledge that Classplus Lite reserves the right to change the offer under this program
							and decide the validity of any referral.
						</p>
					</div>
				</div>
				<div className="share-button-div">
					<button className="share-button" onClick={this.handleClick}>
						<img src={image9} style={{ marginRight: '8px' }} />
						REFER A TEACHER
					</button>
				</div>
			</div>
		);
	}
}

export default Referral;
