import React,{Component,useState} from 'react';
import QuestionBox from "./QuestionBox"
function OtherQuestions(){
 const [data,setData]=useState([
            {clicked:false,
              ques:`How to check your app downloads?`,
              ans:`To check your app downloads, follow these steps:
                    1. Access the Side Menu from the top left of the app.
                    2. Click on the App Downloads option.
                    3. The Symbol “NEW” means that the student is not in your batch.
                    4. Student name will appear who have logged into your App.
                    5. You can also directly add students to batches or call/message them.
                    `
            },
            {clicked:false,
              ques:`How to share your app on Facebook?`,
              ans:`1. Access the Side Menu from the top left of the app.
                   2. Tap on Share on Facebook, you & your students can directly share the app via your Facebook login. `
            },
             {clicked:false,
              ques:`How to add free study material and videos?`,
              ans:`To add free study materials & videos:
                    1. Access Study Material from the Side Menu and click on Add Study material.
                    2. To add a file, click on Add materials.
                    3. Enter the Folder Name & Category Name and Add attachments.
                    4. To attach video links, click on Add videos.
                    5. Paste your youtube links & click on check link thereafter adding the Tittle of video & category.
                    `     
                  }
            ,
            {clicked:false,
              ques:`How to delete student/parent from your app?`,
              ans:`Follow these steps to delete:
                    1. Access the Side Menu from the top left of the app.
                    2. Tap on Settings & click for Reg. Users. 
                    3. Select either students/parents whom you want to delete.
                    4. Tap on Confirm delete.
                    `
            },
            {clicked:false,
              ques:`How to use Refer & Earn?`,
              ans:`To use Refer & Earn: 
                  1. Access the Side Menu from the top left of the app 
                  2. Tap on Refer & Earn.
                  3. Click on the “Click here to refer”.
                  4. Share the details of the institute you want to refer to.

                    `
            },
            {clicked:false,
              ques:`How to create sub-folder in free study material?`,
              ans:`To create sub-folder in free study material:
                  1. Access the Side Menu from the top left of the app 
                  2. Click on Study material & choose the folder to create a sub-folder within.
                  3. Tap on the “+” icon on the top right & choose New Folder to create a sub-folder.
                  4. Enter the sub-folder Name & Category from the dropdown list & click Apply.
                  5. Click on Add Attachments to attach files & click on Add icon. `
            },
            {clicked:false,
              ques:`How to add a student's parent?`,
              ans:`You can add parent in two ways, either through the contact list or manually. To add a student’s parent:
                  1. On the Batches tab, click & choose the batch you want to add the student’s parent.
                  2. Click on “STUDENTS” tab under batch & choose the student.
                  3. Click on Add parents `
            }
            
          ]);

 const handleClicked =index=>{
        setData(data.map((item,i)=>{
            if(i===index){
              item.clicked=!item.clicked
            }
            else{
              item.clicked=false
            }
          return item
        })
        )
    }
    return (
      <div style={{padding:"60px 0 150px 0"}}>
      {data.map((dt,index)=>{
          return(
            <QuestionBox data={dt} index={index} length={data.length} handleClicked={handleClicked}/>
          )
          })
      }
      </div>
    )
}

export default OtherQuestions