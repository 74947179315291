import React, { Component } from "react";
import image1 from "../images/image1.svg";
import image2 from "../images/image2.svg";
import image3 from "../images/image3.svg";
import image4 from "../images/image4.svg";
import image5 from "../images/image5.svg";
import image6 from "../images/image6.svg";
import image7 from "../images/image7.svg";
import image8 from "../images/image8.svg";
import image9 from "../images/send.svg";
import ReferralModal from "./ReferralModal";

class Referral2 extends Component {
  constructor() {
    super();
    this.state = { message: "", openModal: false };
  }

  // componentDidMount() {
  //   fetch("http://dev-testing.classplus.co/v2/freemium/referral/sms", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-access-token": this.getQuery()["token"],
  //     },
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       if (data.status == "failure") {
  //         //   return alert(
  //         //     `${data.message} ${
  //         //       data.data.message ? `: ${data.data.message}` : ""
  //         //     }`
  //         //   );
  //       } else {
  //         return this.setState({ message: data.data.whatsAppText });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }

  // getQuery = () => {
  //   let b = window.location.search
  //     .slice(1)
  //     .split("&")
  //     .map((qStr) => qStr.split("="))
  //     .reduce((acc, inc) => {
  //       acc[inc[0]] = inc[1];
  //       return acc;
  //     }, {});
  //   return b;
  // };

  handleTandC = () => {
    window.open(
      `https://d2sqt6igbl7quy.cloudfront.net/cams/Terms+and+Conditions+-+Teachers'+Day+Referral.pdf`,
      "_blank"
    );
  };

  handleClick = () => {
    // const params = new URL(document.location).searchParams;
    // const user = params.get('user_id');
    // setTimeout(() => {
    // 	window.open(
    // 		`https://play.google.com/store/apps/details?id=my.classroom.app&referrer=utm_source%3Dtutor_reg%26anid%3DtutorReferral{${user}}`,
    // 		'_blank'
    // 	);
    // }, 100);
    // window.open(
    //   `https://api.whatsapp.com/send?text=${this.state.message}`,
    //   "_blank"
    // );
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    const { openModal } = this.state;
    return (
      <div className="referral">
        <img src={image1} alt="image1" style={{ width: "100%" }} />
        <div className="card">
          <div className="firstCard">
            <img src={image4} style={{ marginBottom: "8px" }} />
            <p className="firstcard-blueText ">iss Teacher’s Day ...</p>
            <p className="firstcard-blackText">
              Apne <span className="firstcard-blueText">tutor friends</span> ko
              jode humare saath aur jeetein{" "}
              <span className="firstcard-blueText">aakarshak inaam!</span>
            </p>
            <p className="firstCard-validity">
              Valid from: 5th to 14th September, 2020
            </p>
          </div>

          <div className="secondCard">
            <div className="secondCard-div">
              <p className="secondCard-div-heading">
                On 5 successful* referrals get a{" "}
                <strong>COOLMOBIZ 18 inch</strong> streaming kit to take the
                liveliest live classes.
              </p>
              <img src={image2} style={{ marginLeft: "10px" }} />
            </div>
            <div className="secondCard-div" style={{ marginTop: "24px" }}>
              <img src={image3} style={{ marginRight: "10px" }} />
              <p className="secondCard-div-heading">
                On 10 successful* referrals get a{" "}
                <strong>6-inch wacom one </strong>pen tablet and enhance your
                teaching experience.
              </p>
            </div>
          </div>
          <div className="thirdCard">
            <div className="thirdCard-heading">
              <p className="thirdCard-heading-head">How it works</p>
              <button
                className="thirdCard-heading-tc"
                onClick={this.handleTandC}
              >
                Terms & conditions
              </button>
            </div>
            <div className="thirdCard-content">
              <img src={image5} />
              <p style={{ margin: "0px", padding: "12px 16px" }}>
                Share the app link with your colleagues on WhatsApp using the
                button below.
              </p>
            </div>
            <div className="thirdCard-content">
              <img src={image6} />
              <p style={{ margin: "0px", padding: "12px 16px" }}>
                Your referred teacher signs up on the Classplus Lite app using
                the link you shared.
              </p>
            </div>
            <div className="thirdCard-content">
              <img src={image7} />
              <p style={{ margin: "0px", padding: "12px 16px" }}>
                They create a batch and add students to it. Students sign up on
                the app.
              </p>
            </div>
            <div className="thirdCard-content">
              <img src={image8} />
              <p style={{ margin: "0px", padding: "12px 16px" }}>
                Sit back, relax and wait for the prize to land on your doorstep.
              </p>
            </div>
            <div className="thirdCard-footer ">
              *A successful referral corresponds to the referred teacher
              downloading, signing up, creating a batch and adding a minimum of
              1 student to it on the Classplus Lite mobile app.
            </div>
          </div>
        </div>
        <div className="share-button-div">
          <button
            style={{ background: "#009AE0", fontSize: "1.8rem" }}
            className="share-button"
            onClick={this.handleClick}
          >
            <img src={image9} style={{ marginRight: "8px" }} />
            SHARE WITH TUTORS
          </button>
        </div>
        {openModal ? <ReferralModal closeModal={this.closeModal} /> : ""}
      </div>
    );
  }
}

export default Referral2;
