import React, { Component, useState } from 'react';
import QuestionBox from './QuestionBox';
function AnnouncementQuestions() {
	const [ data, setData ] = useState([
		{
			clicked: false,
			ques: `How to make a notice?`,
			ans: `To make a notice:
                    1. On the Batches tab, click & choose the batch you want to send an notice.
                    2. Tap on notice tab & click “Make an notice.
                    3. Type the notice & add attachments, if any. 
                    4. Click Send to make the notice live.`
		},
		{
			clicked: false,
			ques: `How to do multiple batch notice?`,
			ans: `To make multiple batch notice:
                    1. Click on “CHATS” tab in the navigation panel on the bottom.
                    2. Tap on “+” icon & then on Multiple batch notices.
                    3. Choose & add batches for the notice.
                    4. Type your notice & add attachments, if any and Send.`
		}
	]);

	const handleClicked = (index) => {
		setData(
			data.map((item, i) => {
				if (i === index) {
					item.clicked = !item.clicked;
				} else {
					item.clicked = false;
				}
				return item;
			})
		);
	};

	return (
		<div style={{ padding: '60px 0 150px 0' }}>
			{data.map((dt, index) => {
				return <QuestionBox data={dt} index={index} length={data.length} handleClicked={handleClicked} />;
			})}
		</div>
	);
}

export default AnnouncementQuestions;
