import React, { Component } from 'react';
import whatsapp from '../images/whatsappImg.svg';
import cover from '../images/coverImage.svg';
import learn from '../images/learn.svg';
import communicate from '../images/commu.svg';
import measure from '../images/measure.svg';
class ParentHeader extends Component {
	handleClick = () => {
		let message = `Dear Teacher,

More than 5,000 teachers are teaching over 1,00,000 students online everyday using the My Coaching App!

Take your classroom online in less than 60 seconds. Download the app now: https://bit.ly/2CZNL4d. You can use the app to

👩‍🎓 Create and manage batches of students
📹 Conduct live classes
💬 Chat with parents and students
📘 Share study material and take tests
📣 Send announcements
✅ And much more ...`;
		window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, `_blank`);
	};
	render() {
		return (
			<div className="container">
				<div className="firstBox">
					<p className="firstBox-heading">Are you doing enough for your child’s education?</p>
					<p className="firstBox-subheading">
						<span style={{ color: '#00A7F3' }}>My Coaching App </span>helps your child’s tuition center to
						go digital and improve their learning experience during these difficult times.
					</p>
					<button className="whatsapp-button" onClick={this.handleClick}>
						<img src={whatsapp} alt="whatsapp" style={{ marginRight: '8px' }} />Share App With Tutors
					</button>
					<p className="firstBox-secondHeading">Uske bhavishya ki neev kahin kachhi na rah jaaye</p>
					<p className="firstBox-secondSubheading">उसके भविष्य की नींव कहीं कच्ची न रह जाए</p>
					<img src={cover} alt="cover" />
					<p className="firstBox-text">
						More than{' '}
						<span style={{ color: '#00A7F3', fontWeight: '600', fontSize: '26px' }}>1,00,000</span> students
						have begun their online learning journey using the{' '}
						<span style={{ color: '#00A7F3', margin: '0px' }}>My Coaching App</span>
					</p>
				</div>

				<div className="secondBox">
					<p className="secondBox-heading">APP FEATURES</p>
					<p className="secondBox-subheading">Enabling tutors to embrace the future</p>
					<p className="secondBox-text">
						One platform with all the features that your child’s tution center will need to improve the
						learning experience.
					</p>
				</div>
				<div className="thirdBox">
					<div className="thirdBox-item">
						<div className="thirdBox-left">
							<img src={learn} alt="learn" />
						</div>
						<div className="thirdBox-right">
							<p className="thirdBox-right-heading">Learn</p>
							<p className="thirdBox-right-subheading">
								Tutors can conduct <strong style={{ color: '#212326' }}> Live Classes</strong>, share
								study material such as <strong style={{ color: '#212326' }}>Notes</strong>,{' '}
								<strong style={{ color: '#212326' }}>Video Lectures</strong>, assign{' '}
								<strong style={{ color: '#212326' }}>Homework</strong> and much more
							</p>
						</div>
					</div>
					<div className="thirdBox-item">
						<div className="thirdBox-left">
							<img src={communicate} alt="communicate" />
						</div>
						<div className="thirdBox-right">
							<p className="thirdBox-right-heading">Communicate</p>
							<p className="thirdBox-right-subheading">
								Stay connected with teachers <strong style={{ color: '#212326' }}>24x7</strong> using
								our <strong style={{ color: '#212326' }}>Chat</strong> platform. Help your child clear
								their doubts!
							</p>
						</div>
					</div>
					<div className="thirdBox-item" style={{ borderBottom: '1px solid #E5E5E5' }}>
						<div className="thirdBox-left">
							<img src={measure} alt="measure" />
						</div>
						<div className="thirdBox-right">
							<p className="thirdBox-right-heading">Measure</p>
							<p className="thirdBox-right-subheading">
								Measure your child's performance and help them improve!
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ParentHeader;
