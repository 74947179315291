import React, { Component } from 'react';
import Landing from './Landing';
import PointerSection from './PointerSection';
import Testimonial from './Testimonial';
import FrequentQuestions from './FrequentQuestions';
import TestimonialImage from './TestimonialImage';
import whatsapp from '../images/whatsappImg.svg';
import ParentHeader from './ParentHeader';
class ParentLanding extends Component {
	constructor() {
		super();
		this.state = {
			prevScrollpos: window.pageYOffset,
			visible: false,
			isParent: 1
		};
	}
	handleClick = () => {
		let message = `Dear Teacher,

More than 5,000 teachers are teaching over 1,00,000 students online everyday using the Classplus Lite mobile app!

Take your classroom online in less than 60 seconds. Download the app now: https://bit.ly/2CZNL4d. You can use the app to

👩‍🎓 Create and manage batches of students
📹 Conduct live classes
💬 Chat with parents and students
📘 Share study material and take tests
📣 Send announcements
✅ And much more ...`;
		window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, `_blank`);
		// window.open(`https://api.whatsapp.com/send?phone=0000000000&text=${encodeURIComponent(message)}`, `_blank`)
	};

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);

       
			setTimeout( () => {
				let chatBotElement = document.getElementById('chat-bot-launcher-container');
				console.log('hey timeout', chatBotElement);

				if (chatBotElement) {
			chatBotElement.style.display = 'none';
				}
		} , 8000);
        
	}
	// Remove the event listener when the component is unmount.
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		const { prevScrollpos } = this.state;
		const visible = prevScrollpos > 200;

		this.setState({
			prevScrollpos: window.pageYOffset,
			visible
		});
	};

	render() {
		let { visible, isParent } = this.state;
		return (
			<div>
				<ParentHeader />
				<TestimonialImage />
				<Testimonial isParent={isParent} />
				{visible ? (
					<div className="button-background">
						<button className="button-fixed" onClick={this.handleClick}>
							<img src={whatsapp} alt="whatsapp" style={{ marginRight: '8px' }} />Share App With Tutors
						</button>
					</div>
				) : null}
			</div>
		);
	}
}

export default ParentLanding;
