import React from 'react';
import colen from '../images/colen.svg';
export const ImageCard = ({ data }) => {
	return (
		<div className="image-box">
			<img src={data.image} className="image-data" />
		</div>
	);
};

export default ImageCard;
