import React from "react";

import PropTypes from "prop-types";

import classes from "./Carousel.module.css";
import leftScroll from "./assets/leftScroll.svg";
import leftScrollDisabled from "./assets/left-scroll-disabled.svg";
import rightScroll from "./assets/rightScroll.svg";
import rightScrollDisabled from "./assets/right-scroll-disabled.svg";

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: this.props.activeSlide,
      elementWidth: 270
    };
    this.ul_ref = React.createRef();
    this.imgDownloadAnchors = [];
  }

  moveLeft = evt => {
    evt && evt.stopPropagation();
    this.setState(({ activeSlide }) => {
      return { activeSlide: activeSlide ? activeSlide - 1 : 0 };
    });
  };

  moveRight = evt => {
    evt && evt.stopPropagation();
    if (this.state.activeSlide <= this.state.slides - 1) {
      this.setState(({ activeSlide }) => {
        return {
          activeSlide: activeSlide + 1
        };
      });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.activeSlide != this.props.activeSlide &&
      this.props.activeSlide > this.state.activeSlide + this._windowLength - 1
    ) {
      this.moveRight();
    }
    if (
      prevProps.activeSlide != this.props.activeSlide &&
      this.props.activeSlide < this.state.activeSlide
    ) {
      this.moveLeft();
    }
    if (this.props.elements !== prevProps.elements) {
      this.setingWidth();
    }
  }

  download = e => {
    e.preventDefault();
    var link = document.createElement("a");

    link.style.display = "none";
    link.setAttribute("target", "_blankl");

    document.body.appendChild(link);

    for (var i = 0; i < this.props.elements.length; i++) {
      // link.setAttribute("href", this.props.elements[i]);
      // link.click();
      window.open(this.props.elements[i]);
    }

    document.body.removeChild(link);
  };
  returningDots = () => {
    let arr = [];
    for (let i = 0; i < this.state.slides; i++) {
      arr.push(
        <span
          key={i}
          onClick={() => {
            this.setState({
              activeSlide: i
            });
          }}
          className={
            classes.dots +
            " " +
            (this.state.activeSlide == i ? classes.activeDot : "")
          }
        >
          &nbsp;
        </span>
      );
    }
    return arr;
  };
  componentDidMount() {
    this.setingWidth();
  }

  setingWidth = () => {
    let translateBy = this._container.clientWidth;
    // let slides = Math.ceil(
    //   (this.ul_ref.current.scrollWidth - translateBy) /
    //     (this._container.clientWidth)
    // );
    let slides=this.props.elements.length
    let elementWidth =
      this.ul_ref.current.children && this.ul_ref.current.children.length
        ? this.ul_ref.current.children[0].clientWidth
        : this.props.elementWidth;
    if (this.ul_ref.current.scrollWidth < this._container.clientWidth) {
      slides = 1;
    }
    if (this.props.isBannerType) {
      slides = this.props.elements.length;
      translateBy = this._container.clientWidth;
    }
    let maxTranslate =
      this.ul_ref.current.scrollWidth - this._container.clientWidth;

    this.setState({
      translateBy,
      slides,
      maxTranslate,
      elementWidth
    });
    this._windowLength =
      2 * parseInt(this._container.clientWidth / elementWidth) - 1;
  };

  render() {
    // console.log(this.props.elements)
    let { elements, width } = this.props;
    let { elementWidth } = this.state;
    let { activeSlide } = this.state;
    let objectToSpread = {
      background: "transparent",
      width:"100%",
      margin:"36px 0"
    };
    if (this.props.isBannerType) {
      objectToSpread.width = "100%";
    }
    return (
      <div
        className={this.props.styleClass ? this.props.styleClass : ""}
        style={{
          marginTop: "2%",
          width: "100%"
        }}
      >
        <div
          className={classes.Container}
          style={{ width }}
          ref={elm => (this._container = elm)}
        >
          {this.props.head && (
            <div className={classes.carouselHeader}>{this.props.head}</div>
          )}
          <div
            style={{
              overflow: "hidden"
            }}
          >
            <ul
              className={classes.carouselUl+" "+(this.props.listStyle?this.props.listStyle:"")}
              ref={this.ul_ref}
              style={{
                transform: `translate(${
                  this.state.slides == 1
                    ? 0
                    : -1 *
                      Math.min(
                        activeSlide * this.state.translateBy,
                        this.state.maxTranslate
                      )
                }px)`
              }}
            >
              {elements.map((elem, i) => (
                <li
                  key={elem + i}
                  style={{
                    ...objectToSpread
                  }}
                >
                  {elem}
                
                </li>
              ))}
            </ul>
          </div>
          {this.state.slides > 1 && !this.props.loading && (
            <>
              {activeSlide !== 0 && (
                <button className={classes.Left}>
                  <span
                    className={
                      classes.roundButton +
                      " " +
                      (activeSlide == 0 ? classes.disabledRound : "")
                    }
                    onClick={this.moveLeft}
                  >
                    <img
                      style={{
                        transform: "rotate(180deg)",
                        marginRight: "2px"
                      }}
                      src={activeSlide > 0 ? rightScroll : rightScrollDisabled}
                      alt=""
                    />
                  </span>
                </button>
              )}
              {!(this.state.activeSlide >= this.state.slides - 1) && (
                <button className={classes.Right}>
                  <span
                    className={
                      classes.roundButton +
                      " " +
                      (this.state.activeSlide >= this.state.slides - 1
                        ? classes.disabledRound
                        : "")
                    }
                    onClick={this.moveRight}
                  >
                    <img
                      src={
                        this.state.activeSlide >= this.state.slides - 1
                          ? rightScrollDisabled
                          : rightScroll
                      }
                      alt=""
                    />
                  </span>
                </button>
              )}
            </>
          )}
        </div>
        {this.state.slides > 1 &&
          !this.props.loading &&
          !this.props.dotsDisabled && (
            <div className={classes.dotNavigations}>{this.returningDots()}</div>
          )}
      </div>
    );
  }
}

Carousel.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.element).isRequired,
  style: PropTypes.object,
  showButtons: PropTypes.bool
};

export default Carousel;
