import React from 'react';
import colen from '../images/colen.svg';
export const TestCard = ({ data }) => {
	return (
		<div className="testi-box">
			<img src={colen} alt="colen" className="testi-colen" />
			{/* <p className="testi-head">{data.heading}</p> */}
			<p className="testi-content">{data.content}</p>
			<div className="testi-image-div">
				{data.image ? <img src={data.image} className="testi-image" /> : null}
				<div>
					<p className="testi-name">{data.name}</p>
					{data.institute ? <p className="testi-institute">{data.institute}</p> : null}
				</div>
			</div>
		</div>
	);
};

export default TestCard;
