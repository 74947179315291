import React, { Component } from 'react';
import Tabs from './Tabs/Tabs';
import EventQuestions from './EventQuestions';
import ChatQuestions from './ChatQuestions';
import AssignmentQuestions from './AssignmentQuestions';
import AnnouncementQuestions from './AnnouncementQuestions';
import OtherQuestions from './OtherQuestions';
import TestQuestions from './TestQuestions';
import AttendanceQuestions from './AttendanceQuestions';
import ParentQuestion from './ParentQuestion';
import StudentQuestion from './StudentQuestion';
const componentMap = {
	Chats: ChatQuestions,
	Homework: AssignmentQuestions,
	Events: EventQuestions,
	Notices: AnnouncementQuestions,
	Others: OtherQuestions
};

const componentMapStudent = {
	Parent: ParentQuestion,
	Student: StudentQuestion
};
class ProductDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 1,
			tabsArray: [
				// {
				// id: 0,
				// label: "Tests",
				// },
				// {
				// id: 1,
				// label: "Attendance",
				// },
				{
					id: 1,
					label: 'Chats'
				},
				{
					id: 2,
					label: 'Homework'
				},

				{
					id: 3,
					label: 'Events'
				},
				{
					id: 4,
					label: 'Notices'
				},

				{
					id: 5,
					label: 'Others'
				}
			],
			selectedItem: 'Chats'
			// studentSelectedTab:0,
			// studentTabArray:[
			//   {
			//     id: 0,
			//     label: "Parent",
			//     },
			//     {
			//     id: 1,
			//     label: "Student",
			//     },
			// ],
			// studentSelectedItem:"Parent"
		};
	}

	handleSelectOption = (e) => {
		this.setState({ selectedItem: e.target.value });
	};

	// handleStudentSelectOption=(e)=>{
	//   this.setState({studentSelectedItem:e.target.value})
	// }

	render() {
		let {
			selectedTab,
			tabsArray,
			selectedItem,
			studentSelectedItem,
			studentSelectedTab,
			studentTabArray
		} = this.state;
		let { user } = this.props;
		let screenwidth = window.innerWidth;
		return (
			// user && user!=3 ?
			//   screenwidth > 1000 ?
			//       <Tabs
			//         key={studentSelectedTab}
			//         tabsArray={studentTabArray}
			//         selectedTabId={studentSelectedTab}
			//         selectTab={(id) => {
			//           this.setState({ studentSelectedTab: id });
			//         }}
			//         component={
			//           componentMapStudent[studentTabArray.find((tab) => tab.id === studentSelectedTab).label]
			//         }
			//       />
			//       :
			//       <div className="product-mobile-view">
			//           <p className="product-mobile-heading">Select FAQ Type</p>
			//           <select className="product-drop" onChange={this.handleStudentSelectOption}>
			//               <option value="Parent">Parent</option>
			//               <option value="Student">Student</option>
			//           </select>

			//           <div>
			//             {studentSelectedItem==="Parent" ?
			//             <ParentQuestion/>:
			//             studentSelectedItem==="Student"?
			//             <StudentQuestion/>:
			//             <ParentQuestion/>
			//             }

			//           </div>

			//       </div>
			// :
			screenwidth > 1000 ? (
				<Tabs
					key={selectedTab}
					tabsArray={tabsArray}
					selectedTabId={selectedTab}
					selectTab={(id) => {
						this.setState({ selectedTab: id });
					}}
					component={componentMap[tabsArray.find((tab) => tab.id === selectedTab).label]}
				/>
			) : (
				<div className="product-mobile-view">
					<p className="product-mobile-heading">Select FAQ Type</p>
					<select className="product-drop" onChange={this.handleSelectOption}>
						{/* <option value="Tests">Tests</option> */}
						{/* <option value="Attendance">Attendance</option> */}
						<option value="Chats">Chats</option>
						<option value="Homework">Homework</option>
						<option value="Events">Events</option>
						<option value="Notices">Notices</option>
						<option value="Others">Others</option>
					</select>

					<div>
						{//   selectedItem==="Tests" ?
						// <TestQuestions/>:
						// selectedItem==="Attendance"?
						// <AttendanceQuestions/>:
						selectedItem === 'Chats' ? (
							<ChatQuestions />
						) : selectedItem === 'Homework' ? (
							<AssignmentQuestions />
						) : selectedItem === 'Events' ? (
							<EventQuestions />
						) : selectedItem === 'Notices' ? (
							<AnnouncementQuestions />
						) : selectedItem === 'Others' ? (
							<OtherQuestions />
						) : (
							<ChatQuestions />
						)}
					</div>
				</div>
			)
		);
	}
}

export default ProductDetails;
